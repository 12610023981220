import React, { useState } from "react"
import LayoutEng from "../../components/LayoutEng"
import SEO from "../../components/SEO"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const Services = ({data}) => {
  const services = data.service.nodes

  return (
    <LayoutEng>
      <SEO title="Services" />
      <main className="page media-page">
      <div className="center">
      <h1><strong className="pageNaslov">Apsiha </strong>services</h1>
      </div>
      <div className="whiteSection boxedWhite" id="services">
          <div className="whiteSection-container">
            <div className="whiteSection-text">
            <div className="tags-list" id="tagsEng">
            {services.map(services =>( 
              <Link to={"../../Services/" + services.frontmatter.slug} key={services.id}>
                <div className="tagThumb">
                <GatsbyImage
                  image={getImage(services.frontmatter.thumb.childImageSharp.gatsbyImageData)}
                  alt={services.frontmatter.title}
                  />
                  <div className="tagDescript">
                  <h3><strong>{services.frontmatter.title}</strong></h3>
                  <p>{services.frontmatter.description}</p>
                  </div>
                </div>
              </Link>
            ))}
        </div>
          </div>
          </div>
        </div>
      </main>
    </LayoutEng>
  )
}

export default Services

export const query = graphql` 
query Services {
    service: allMarkdownRemark(
      sort: {fields: frontmatter___order, order: ASC}
      filter: {frontmatter: {type: {eq: "services_input"}}}
    ) {
      nodes {
        frontmatter {
          slug
          order
          title
          description
          thumb {
            childImageSharp {
              gatsbyImageData(
                placeholder: DOMINANT_COLOR
                transformOptions: {fit: CONTAIN}
                width: 450
              )
            }
          }
        }
        id
      }
    }
  }
`
